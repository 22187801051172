<template>
  <div>
    <b-card>
      <div class="row mb-3">
        <div class="col-12 d-flex">
          <span class="h2 mr-auto text-secondary"> Nouveau concept</span>

          <div>
            <modal-actions @close="$router.go(-1)" />
          </div>
        </div>
      </div>
      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <span class="h3 text-secondary">Informations générales</span>
          <div>
            <b-button
              @click="createItem"
              type="submit"
              pill
              :disabled="submitingForm"
            >
              <b-spinner
                small
                v-if="submitingForm"
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
          </div>
        </div>
        <hr class="mt-1 mb-3 bg-secondary" />

        <div class="row">
          <div class="col-12">
            <b-form-group
              label-cols="12"
              label="Nom"
              label-class="font-weight-bold"
            >
              <b-form-input
                v-model="newConcept.name"
                :class="{
                  'is-invalid':
                    $v.newConcept.name.$error && $v.newConcept.name.$dirty,
                }"
                type="text"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newConcept.name.$dirty"
              >
                {{
                  !$v.newConcept.name.required
                    ? "champ obligatoire"
                    : !$v.newConcept.name.minLength
                    ? `Le champ doit contenir au moins ${$v.newConcept.name.$params.minLength.min} caractères.`
                    : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
            <b-form-group
              label-cols="12"
              label="Description"
              label-class="font-weight-bold"
            >
              <b-form-textarea
                rows="6"
                v-model="newConcept.description"
                placeholder="Description"
              ></b-form-textarea>
            </b-form-group>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { required, minLength } from "vuelidate/lib/validators"; //validation

export default {
  components: {
    ModalActions,
  },
  data: () => ({
    submitingForm: false,

    newConcept: {
      name: "",
      description: "",
    },
  }),
  validations: {
    newConcept: {
      name: {
        required,
        minLength: minLength(5),
      },
    },
  },
  methods: {
    createItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        (this.submitingForm = true),
          this.$store
            .dispatch("concept/createConcept", this.newConcept)
            .then(() => {
              this.submitingForm = false;
            });
      }
    },
  },
  computed: {
    ...mapGetters("concept", ["CONCEPT"]),
  },
  watch: {
    CONCEPT() {
      this.submitingForm = false;

      Swal.fire({
        title: "Le concept est bien créé !",
        type: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            name: "show-concept",
            params: {
              slug: this.CONCEPT.slug || "abcde",
              id: this.CONCEPT.id,
              fromCreation: true,
            },
          });
        }
      });
    },
  },
};
</script>

<style></style>
